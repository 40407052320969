input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: transparent;
    font-size: 16px;
    color: white;
}

input:focus {
    outline: none;
    box-shadow: 2px 3px 20px 1px rgba(0, 0, 0, 0.3);
}

.iconContainer {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 20px;
    width: 20px;
}
