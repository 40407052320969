* {
  box-sizing: content-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  font-family: 'ClashDisplay';
  box-sizing: border-box;
}

@font-face {
  font-family: "ClashDisplay";
  src: url("./assets/fonts/ClashDisplay/ClashDisplay-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "ClashDisplay";
  src: url("./assets/fonts/ClashDisplay/ClashDisplay-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "ClashDisplay";
  src: url("./assets/fonts/ClashDisplay/ClashDisplay-Bold.otf") format("opentype");
  font-weight: 700;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  /* border-radius: 50px; */
  width: 8px;
  height: 8px;

  border: 3px solid rgba(255,255,255,0);
	background-clip: padding-box;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(20, 225, 225, 1);
  border-radius: 80px;
  width: 10px;
  height: 10px;

  border: 3px solid rgba(255,255,255,0);
	background-clip: padding-box;
}
